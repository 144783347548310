<template>
  <!--begin::Post-->
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container">
      <!--begin::Basic info-->
      <div class="card mb-5 mb-xl-10">
        <!--begin::Card header-->
        <div
          class="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <!--begin::Card title-->
          <div class="card-title m-0">
            <h3 class="fw-bolder m-0">แก้ไขหัวข้อเรื่องย่อย</h3>
          </div>
          <!--end::Card title-->
        </div>
        <!--begin::Card header-->

        <!--begin::Content-->
        <div id="kt_account_profile_details" class="collapse show">
          <!--begin::Form-->
          <Form
            v-if="sub_title"
            @submit="onSubmitSubTitle"
            :validation-schema="data_sub_title"
            id="kt_account_profile_details_form"
            class="form"
            novalidate="novalidate"
          >
            <!--begin::Card body-->
            <div class="card-body border-top p-9">
              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >หัวข้อเรื่อง</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field type="hidden" name="id" v-model="sub_title.id" />
                  <Field
                    name="id_title"
                    class="form-control h-auto form-control-solid py-4 px-8"
                    as="select"
                    v-model="sub_title.id_title"
                  >
                    <option value="" selected disabled>โปรดเลือก</option>
                    <option
                      v-for="title in titlesList"
                      :key="title.id"
                      :value="title.id"
                    >
                      {{ title.name }}
                    </option>
                    <!--:selected="value && value.includes(role.id)"-->
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="id_title" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >หัวข้อเรื่องย่อย</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    v-model="sub_title.name"
                    type="text"
                    name="name"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="หัวข้อเรื่องย่อย"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="name" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
            </div>
            <!--end::Card body-->

            <!--begin::Actions-->
            <div class="card-footer d-flex justify-content-end py-6 px-9">
              <button
                type="submit"
                id="kt_account_profile_details_submit"
                ref="submitButton"
                class="btn btn-primary"
              >
                <span class="indicator-label"> ยืนยัน </span>
                <span class="indicator-progress">
                  กรุณารอสักครู่ ...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
            </div>
            <!--end::Actions-->
          </Form>
          <!--end::Form-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Basic info-->
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, computed } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as Yup from "yup";
import useSweetalert from "@/core/helpers/sweetalert2";
import useSubTitles from "@/core/services/api/appeal/sub_titles";
import { checkPage } from "@/core/helpers/checkpage";
import { useRouter } from "vue-router";
import JwtService from "@/core/services/JwtService";
export default defineComponent({
  name: "sub-title-edit",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: ["id"],
  setup(props) {
    let CryptoJS = require("crypto-js");

    const router = useRouter();

    const { sub_title, getSubTitle, updateSubTitle, titles } = useSubTitles();
    const { Sconfirm, SToast } = useSweetalert();
    const submitButton = ref(null);
    const data_sub_title = Yup.object().shape({
      id_title: Yup.string()
        .required("กรุณากรอก หัวข้อเรื่อง")
        .label("หัวข้อเรื่อง"),
      name: Yup.string()
        .required("กรุณากรอก หัวข้อเรื่องย่อย")
        .label("หัวข้อเรื่องย่อย"),
    });

    const onSubmitSubTitle = (values) => {
      Sconfirm("ยืนยัน การแก้ไขหัวข้อเรื่องย่อย", "question").then(
        async (result) => {
          if (result.isConfirmed) {
            if (submitButton.value) {
              // Activate indicator
              submitButton.value.setAttribute("data-kt-indicator", "on");
            }
            await updateSubTitle(values)
              .then(() => {
                submitButton.value?.removeAttribute("data-kt-indicator");
                SToast("success", "แก้ไข ระดับการเข้าถึง สำเร็จ");
              })
              .catch((error) => {
                submitButton.value?.removeAttribute("data-kt-indicator");
                if (error.response) {
                  let errors = error.response.data.errors;

                  if (errors.name) {
                    SToast("error", `${errors.name[0]}`);
                  }
                }
              });
          }
        }
      );
    };
    const titlesList = computed(() => {
      let roles = JSON.parse(JwtService.getPermissions());
      let bytes = CryptoJS.AES.decrypt(roles, process.env.VUE_APP_KEY);
      let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      return titles.value.filter((titles) =>
        decryptedData.includes(titles.parameter)
      );
    });
    onMounted(() => {
      if (!props.id) {
        return router.push({ name: "apps.appeals.sub-titles.index" });
      }
      getSubTitle(props.id);
      checkPage("sub-title-edit");
      setCurrentPageBreadcrumbs("แก้ไขหัวข้อเรื่องย่อย", [
        "จัดการหัวข้อเรื่องย่อย",
      ]);
    });

    return {
      titlesList,
      sub_title,
      data_sub_title,
      submitButton,
      onSubmitSubTitle,
    };
  },
});
</script>

<style></style>
